.business-card{
    display: flex;
    align-self: center;
    flex-direction: column;
    background-color: white;
    padding: 1em;
    border-radius: 2px;
    color: rgb(53, 53, 53);
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.profile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: .5em;
}

.profile>h3{
    margin-bottom: .25em;
}

.since{
    margin-top: .25em;
    margin-bottom: .5em;
    color: #B0B0B0;
    font-style: italic;
}

.full{
    display: flex;
    flex: 1;
}

.clickable {
    cursor: pointer;
}

a {
    margin:0;
    padding: .5em;
    padding-left: 0;
}

.name {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: .5em;
}

.name>a{
    margin-left: 10px;
    text-decoration: none;
    font-style: italic;
    color: rgb(133, 133, 133);
}

.badge-row {
   display: flex;
   flex-direction: row;
   height: 3em;
   align-items: stretch;
   justify-content: flex-start;
}

.badge{
    display: flex;
    object-fit: contain;
    width: 3em;
 }